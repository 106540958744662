.work-legend {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    height: 3rem;
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    display: flex;


    background-color: rgba(0, 0, 0, 0.03);
    color: #fff;
    font-size: 1.2rem;
    line-height: 2 rem;
    font-family: var(--primary-font);
    

    text-align: center;
    z-index: 1;
}

.work-carousel-image {
    max-height: 42rem;
    object-fit: cover;
}

.work-legend:hover {
    background-color: rgba(0, 0, 0, 0.6);
}