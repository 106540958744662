.game-link {
    display: flex;
    /* width: 60%; */
    align-self: center;
    justify-self: center;
    align-items: center;
    justify-content: center;
    margin: 10px;
}

.game-link-image {
    width: 100%;
    height: 100%;

    box-shadow: 0px 0px 5px var(--dark-font-color);
}

.game-link-link {
    text-decoration: none;
}

.game-link-text {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    color: var(--background-color);

    border-radius: 0.5rem;
    /* background-color: var(--project-highlight-color); */
    padding: 1.5rem;

    font-size: 1.3rem;
    font-weight: 600;
    transition: 0.25s ease;
}

.game-link-text:hover {
    transition: 0.25s ease;
    /* background-color: var(--extra-dark-font-color); */
}
