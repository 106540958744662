.a-title {
    font-family: var(--primary-font);
    color: var(--background-color);
    padding: 2rem;

    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    

    text-shadow: 4px 4px 4px var(--dark-font-color);

    font-size: 5rem;
    height: 350px;
    font-weight: 600;
    text-align: center;
    align-content: center;
    align-items: center;

    display: flex;
    justify-content: center;
    /* background-position-y: -30vh;` */
}

.a-flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: center;
    /* align-items: center; */
    /* width: 70%; */
}

.a-main {
    width: 100%;
}

.a-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    width: 80%;
}

.a-subtitle {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    font-size: 2rem;
    font-weight: 600;
}


.a-game {
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
}

.a-game-link {
    text-decoration: none;
    font-weight: 500;
    font-size: 2.5rem;
    font-family: var(--primary-font);
    color: var(--background-color);
    border-radius: 1rem;
    padding: 1.5rem;
    transition: 0.5s ease;
}

.a-game-link:hover {
    transition: 0.5s ease;
}

.a-top {
    display: flex;

    margin-top: 2rem;

    margin-left: 10rem;
    margin-right: 10rem;
    background-color: var(--background-color-opacity);
}

.a-row-div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.a-reverse {
    flex-direction: row-reverse;

}

@media screen and (max-width: 1000px) {
    .a-row-div
    {
        flex-direction: column;
    }

    .a-reverse {
        flex-direction: column;
    }
}

.a-column-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.a-left {
    display: flex;
    flex-direction: column;
    flex: 2;
    align-self: center;
}

.a-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--background-color-opacity);
    margin: 2%;
}

.a-description {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    background-color: var(--background-color-opacity);

    padding-right: 3rem;

    font-size: 1.25rem;
    font-weight: 400;
    white-space: pre-line;
}

.a-vertical-divider {
    width: 2px;
    background-color: var(--light-highlight-color);
    margin: 2rem;
}

.a-details-base {
    display: flex;
    flex-direction: column;
}

.a-detail {
    display: flex;
    flex-direction: column;
}

.a-span-bold {
    color: var(--extra-dark-font-color);
    font-weight: 600;
}

.a-details-heading {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    background-color: var(--background-color-opacity);

    font-size: 1.2rem;
    font-weight: 600;

    margin-top: 1rem;
}

.a-details-detail {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    background-color: var(--background-color-opacity);

    font-size: 1.2rem;
    font-weight: 400;

    /* margin: 1.5rem; */
    margin-top: 0.7rem;
    margin-bottom: 1rem;
    width: 100%;
}

.a-base {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.a-plain-para {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    background-color: var(--background-color-opacity);

    align-self: center;
    width: 100%;
    font-size: 1.25rem;
    font-weight: 400;
    padding: 1rem;
    margin: 2%;
}

.a-image {
    align-self: center;
    margin: 2rem;
    max-width: 100%;
    width: 100%;
    transition: 0.5s ease;
    filter: drop-shadow(0px 1rem 1rem rgba(0, 0, 0, 0.25));
}

.a-image-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    max-width: 30%;
    margin: 2rem;
    width: 100%;
}



@media screen and (max-width: 1000px) {
    .a-top {
        flex-direction: column;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 0rem;
    }

    .a-title {
        font-size: 3rem;
        max-height: 20rem;
        background-attachment: scroll;
    }

    .a-description {
        text-align: center;
        padding: 0;

    }


    .a-vertical-divider {
        width: 100%;
        height: 2px;
        background-color: var(--light-highlight-color);
        border-radius: 2rem;
        margin: 1.5rem;
    }

    .a-image-row {
        max-width: 100%;
        margin: 0;
    }

}

.a-link-image {
    /* width: 10rem; */
    height: 10rem;
    /* Dont stretch the image */
    /* object-fit: cover; */
    background-color: var(--background-color-opacity);

}


.a-nonlink-image {
    /* width: 10rem; */
    height: 19rem;
    /* Dont stretch the image */
    /* object-fit: cover; */
    /* background-color: var(--background-color-opacity); */

}

.a-link {
    display: inline-block;
    margin: 1rem;
    transition: 0.5s ease;
    filter: drop-shadow(0px 1rem 1rem rgba(0, 0, 0, 0.25));
}

.a-link:hover {
    transition: 0.5s ease;
    transform: translateY(-5%);
}

.a-link-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem;

}

.a-plain-para-center {
    text-align: center;
}

.a-plain-list {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    background-color: var(--background-color-opacity);

    align-self: center;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;

    font-size: 1.5rem;
    font-weight: 400;
    padding: 1rem;
    margin: 2%;
}

.a-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50vh, 1fr));
    grid-gap: 2rem;
}

.a-grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px var(--dark-font-color);
}