.contact-icons {
    display: flex;
    justify-content: center;
}

.contact {
    display: flex;
    flex-direction: column;
    background-color: var(--dark-overlay-color);
    padding: 1rem;
    width: 100%;
    /* margin-top: auto; */
}

.contact-heading {
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--background-color);
    font-size: 2rem;
    font-weight: 500;
    font-family: var(--primary-font);
    letter-spacing: 0.1rem;
    transition: all 0.3s ease-in-out;

    border-radius: 0.5rem;
}

.contact-text {
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;
    color: var(--background-color);
    font-size: 1.5rem;
    font-weight: 400;
    font-family: var(--primary-font);
    letter-spacing: 0.1rem;
    margin: 0 1rem;
    padding: 1rem 1rem;
    transition: 0.3s ease-in-out;

    border-radius: 0.5rem;
}

.contact-text:hover {
    background-color: var(--background-color);
    transition: 0.3s ease-in-out;
    color: var(--dark-font-color);
}


@media screen and (max-width: 1000px) {
    .contact-icons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .contact-text {
        width: fit-content;
        margin: 0.2rem;
    }
}