.project-title {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    font-family: var(--primary-font);
    
    margin: 1rem;

    border-bottom: 1px solid var(--transparent-color);
    align-self: center;
}

.project-parent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.project-title-link {
    text-decoration: none;
}


.project-read-more {
    text-decoration: none;

    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.project-description {
    color: var(--dark-font-color);
    text-align: justify;
    font-family: var(--primary-font);
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 1rem;
}

.project-background {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 2rem;
    margin-top: 2%;
    width: 95%;

}

.blog-preview-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--light-font-color);
    font-family: var(--primary-font);
    font-size: 1.25rem;
}

.project-posts {
    display: grid;
    grid-gap: 4rem; 
    grid-template-columns: repeat(auto-fill, minmax(40vh, 1fr));
    grid-auto-flow: dense;
}

.project-tags {
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: var(--primary-font);
}

.p-tag {
    color: var(--light-font-color);
    font-family: var(--primary-font);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
    margin-top: 0.8rem;
    text-decoration: none;
    margin-right: 1rem;
    border-bottom: 1px solid var(--transparent-color);
}

.p-tag:hover {
    color: var(--dark-font-color);
    border-bottom: 1px solid var(--dark-font-color);
}


.tags {
    display: flex;
    flex-direction: column;

    align-items: center;
    background-color: var(--background-color-opacity);
    padding: 1.5rem;

    font-family: var(--primary-font);
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
    color: var(--dark-font-color);
}

@media (max-width: 768px) {
    .tags {
        display: none;
    }
}

.tag-name {
    margin-top: 1rem;
    color: var(--light-font-color);

    text-decoration: none;
    border-bottom: 1px solid var(--transparent-color);
}

.tag-name.selected {
    color: var(--dark-font-color);
    border-bottom: 1px solid var(--dark-font-color);
}

.tag-name:hover {
    color: var(--dark-font-color);
    border-bottom: 1px solid var(--dark-font-color);
}

.project-preview {
    margin: 0; 
    aspect-ratio: 2/1.2;
    transition: 0.5s ease
}

.project-preview:hover {
    transition: 0.5s ease;
    transform: translateY(-5%)
}

.project-preview-hoverover {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--dark-overlay-color); 
    color: var(--background-color);
    justify-content: center; 
    align-items: center; 
    flex-direction: column; 
    opacity: 0;
    transition: 0.5s ease;

    border-radius: 0.5rem;

}

.project-link:hover .project-preview-hoverover {
    transition: 0.5s ease;
    opacity: 1;
}

.project-image {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
    background-color: var(--background-color-opacity);
    filter: drop-shadow(0px 1rem 1rem rgba(0, 0, 0, 0.25));
}

.project-link {
    text-decoration: none;
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;  
}

.project-top {
    display: flex;
    flex-direction: column;
    align-self: center;

    width: 87%;
}


.horizontal-line {
 margin-top: 1rem;   
}

.highlight-text {
    transition: 0.5s ease;
    border-radius: 0.5rem;
    font-weight: 900;
}

.highlight-text:hover {
    transition: 0.5s ease;
}


.project-top-description {
    display: flex;
    flex-direction: column;
    color: var(--dark-font-color);
    font-family: var(--primary-font);
    font-size: 2.5rem;
    font-weight: 400;
    margin: 2rem;

    .b{
        font-weight: 900;
        display: block;
        transition: 0.5s ease;
    }

    .b:hover {
        transform: translateY(-5%);
        transition: 0.5s ease;
        color: #000;
    }
}

.line-divider {
    width: 100%;
    height: 2px;
    background-color: var(--light-highlight-color);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .project-top {
        width: 100%;
    }

    .project-top-description {
        font-size: 1.75rem;
        margin: 0.25rem;
    }

    .a-description {
        text-align: left;
    }
    
}

.a-description-link {
    text-decoration: none;
    font-weight: 500;
    color: var(--extra-dark-font-color);
}

.a-description-link:hover {
    text-decoration: underline;
}