.about-image {
    height: 27rem;
    box-shadow: 0px 0px 5px var(--dark-font-color);
    border-radius: 0.2rem;
}

.about-background {
    display: flex;
    flex-direction: column;
    width:inherit;
    /* background-color: var(--background-color-opacity); */
}

.about-contact {
    display: flex;
    flex-direction: column;
}

.about-explore {
    margin-top: 5rem;
}

.link {
    text-decoration: none;
    color: var(--dark-highlight-color);
    font-weight: 700;
}

.about-explore-heading 
{
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    font-size: 3rem;
    font-weight: 500;
    text-align: center;

    padding-bottom: 3rem;
    background-color: var(--background-color-opacity);
}

.about-aboutme {
    display: flex;
    flex : 1;
    margin: auto;
    width: 70%;
    /* max-width: 1920px; */

    align-items: center;
    justify-content: center;
}

.about-text {
    padding: 3rem;
    font-family: var(--primary-font);
    color: var(--dark-font-color);

    background-color: var(--background-color-opacity);

    font-size: 1.4rem;
    font-weight: 400;

}

@media screen and (max-width: 1000px) {
    .about-text {
        padding: 1rem;
        font-size: 1.25rem;
    }
    
}

.about-explore-posts {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: auto;

    flex-wrap: wrap;

    width: 60%;
    gap: 3rem;

    margin-bottom: 5rem;
    margin-top: 1rem;
}

.explore-preview {
    display: flex;

    position: relative;

    align-items: center;
    justify-content: center;

    width: 18rem;
    height: 18rem;

    border: 2px solid var(--beaver);
    background-color: var(--background-color-opacity);
    background-color: var(--desert-sand);
}

.explore-link {
    padding: 2rem;
    font-family: var(--primary-font);
    font-size: 1.75rem;
    font-weight: 400;
    text-align: center;
    text-decoration: none;

    color: var(--light-highlight-color);
}

.explore-link:hover {
    color: var(--light-highlight-color);
}

.explore-preview:hover {
    transition: 0.25s;
    border: 2px solid var(--smoky-black);

    background-color: var(--brown-sugar);

    .explore-link {
        transition: 0.25s;
    }
}

.explore-image {

    position: absolute;
    z-index: -1;

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.2rem;
    margin-bottom: 0.2rem;


}

@media screen and (max-width: 1000px) {
    .about-aboutme {
        display: flex;
        flex-direction: column;
        width: 90%;
        /* text-align: center; */
    }
}