.video-responsive {
    overflow: hidden;
    position: relative;
    filter: drop-shadow(0px 1rem 1rem rgba(0, 0, 0, 0.25));
    margin: 2rem;
  }

  .video-responsive iframe {

    align-items: center;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 100%;
    aspect-ratio: 16 / 9;
    /* max-width: 800px; */
  }
  