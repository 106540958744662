@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:wght@100;200;300;400;500;600;700;800;900;1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Oswald:wght@200;300;400;500;600;700&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root {
  --dark-font-color: #465059;
  --extra-dark-font-color: #141e27;
  --light-font-color: #98A0A6;
  --dark-overlay-color: #141e27e6;
  --light-overlay-color: #505a5ae6;
  --project-highlight-color: #F2F7FA;

  --background-color: #F2F7FA;
  --secondary-background-color: rgb(239, 242, 249);

  /* background color with opacity */
  --background-color-opacity: rgba(242, 247, 250, 0.8);
  --background-color-opacity2: rgba(238, 239, 240, 0.8);
  --transparent-color: rgba(255, 255, 255, 0);

  --light-highlight-color: #E4EBF1;
  --primary-font: 'Quicksand', sans-serif;

  --smoky-black: rgba(26, 24, 13, 0.443);
  --brown-sugar: hsla(14, 33%, 68%, 0.43);
  --desert-sand: rgba(233, 197, 170, 0.745);
  --beaver: rgba(146, 125, 111, 0.223);
  --battleship-gray: #7B7566ff;
}