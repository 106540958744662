.mobile-nav {
  width: 100%; 
  height: 100%; 
  position: fixed;
  top: 0; 
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  z-index: 2;
}

.mobile-nav-body {
  height: 100%;
  display: flex;
  justify-content: center;
}

.close-icon {
  cursor: pointer; 
  background-color: var(--transparent-color);
  border: none;
  fill: var(--light-font-color);
  
  width: 4rem;
  height: 4rem;
  justify-self: flex-end;
  align-self: flex-end;
  margin: 1rem; 
}

.mobile-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  align-self: center;
  align-items: center;

  padding: 0;
}

.mobile-nav-link {

  display: flex;
  width: fit-content;

  margin-bottom: 2rem;

  font-family: var(--primary-font);
  font-size: 3.5rem;
  font-weight: 400;
  line-height: normal;
  color: var(--dark-font-color);
  text-decoration: none;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

.mobile-border-line {
  display: flex;

  border-top: 1.2px solid var(--light-highlight-color);
  border-radius: 1px;
  width: 90%;

  align-self: center;
}

.mobile-nav-link:hover {
  color: var(--background-color);
  background-color: var(--light-overlay-color);
}

.mobile-nav-link-active {
  color: var(--background-color);
  background-color: var(--dark-overlay-color);
}

.mobile-nav-tags {
  display: flex;
  flex-direction: column;

  font-family: var(--primary-font);
  font-size: 1.5rem;

  color: var(--light-font-color);

  text-decoration: none;
}

.mobile-tag-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.2rem;
  list-style: none;
  align-self: center;

  width: 70%;
  font-size: 1.25rem;
}

.mobile-tags-heading {
  align-self: center;
  margin: 2rem;
  font-size: 1.25rem;
}

.close-icon:hover {
  fill: var(--dark-font-color);
}