.privacy {
    background-color: var(--background-color-opacity);
    width: 85%;
    margin: 0 auto;
    font-family: var(--primary-font);
    padding: 3rem;
    color: var(--dark-font-color);
    font-size: 1.4rem;

    /* set clickable links to dark highlight color */
    a {
        color: var(--dark-highlight-color);
        font-weight: 700;
    }
}