@import 'variables.css';

.nav-list {
  list-style: none;
  display: flex;
  padding: 1rem;
}

.nav-link {
  color: var(--dark-font-color);
  text-decoration: none;
  padding: 0.85rem;
  margin: 1rem;
  font-family: var(--primary-font);
  font-size: 1.5rem;
  line-height: normal;
  overflow: auto;
  border-radius: 0.5rem;
  transition: 0.3s ease-in-out;
}

.nav-link:hover {
  color: var(--background-color);
  background-color: var(--dark-overlay-color);
  transition: 0.3s ease-in-out;
}

.nav-link-active {
  color: var(--background-color);
  background-color: var(--dark-overlay-color);
  transition: 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .nav-list {
    display: none; 
  }

  .nav-link {
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;

    margin: 4px;
  }

  .nav-list.show {
    display: flex;
  }


  .menu-icon {
    width: 60px;
    cursor: pointer;
    border: none;
    background-color: transparent; 

    margin: 1rem;
  }

  .hamburger-icon {
    fill: var(--light-font-color);
  }

  .hamburger-icon:hover {
    fill: var(--dark-font-color);
  }

}

@media (min-width: 769px) {
  .menu-icon {
    display: none;
  }
}

nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  /* background-color: var(--background-color); */
}

.nav-list.show .close-icon {
  display: block;
  position: absolute;
  top: 25px;
  right: 55px;
}

.heading {
  display: flex;
}

.heading-title {
  font-family: var(--primary-font);
  font-size: 2rem;
  font-weight: 400;
  line-height: normal;
  color: var(--dark-font-color);
  width: 100%;
  text-align: left;
  margin: 1.2rem;
  padding-left: 1rem;
  user-select: none; /* This line will make the text non-selectable */
}

.heading-link {
    text-decoration: none;
    color: inherit;
}