.App {
  /* max-width: 1200px; */
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex: 1;
  width: 100%;
}

body {
  /* display: flex; */
  width: 100%;
  background-color: var(--background-color);
}

.a-base {
  /* background-color: var(--background-color); */
}

.Main {
   margin: 0;
   padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.heading {
  /* background-color: var(--background-color-opacity2); */
}

.heading-title {
  font-family: var(--primary-font);
  color: var(--dark-font-color);
  font-size: 3.5rem;
  font-weight: 600;
  text-align: center;

  padding-bottom: 3rem;
}

.divider {
  height: 2px;
  background-color: var(--light-highlight-color);
  border-radius: 2rem;
  
  margin-bottom: 2rem;

  margin-left: auto;
  margin-right: auto;
}


.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -99;
}
