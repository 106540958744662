.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}


.not-found-text {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    font-size: 3.5rem;
    font-weight: 600;
    text-align: center;

    padding-bottom: 3rem;
}

.not-found-link {
    font-family: var(--primary-font);
    color: var(--dark-font-color);
    font-size: 1.5rem;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    padding: 1.5rem;

    border-radius: 0.5rem;
}

.not-found-link:hover {
    color: var(--background-color);
    background-color: var(--dark-font-color) ;
}